





















































































import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/auth";
import { UserWithPassword } from "@/types/user";
import { getFileObjectByUrl } from "@/utils/filehelper";
import AdminBaseItemCRUDDialog from "@/components/admin/base/item-management/AdminBaseItemCRUDDialog.vue";
import AdminUserAddressTable from "@/components/admin/user/address/AdminUserAddressTable.vue";
import PasswordTextField from "@/components/text-fields/password/PasswordTextField.vue";

/**
 * UserProfile view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemCRUDDialog,
        PasswordTextField,
        AdminUserAddressTable,
    },
})
export default class UserProfile extends Vue {
    private isCRUDDialogVisible = false;
    private changePassword = false;

    /**
     * @return UserWithPassword | null
     */
    get user(): UserWithPassword | null {
        // @ts-ignore
        return AuthModule.user;
    }

    /**
     * Sets user in vuex store
     */
    set user(updatedUser: UserWithPassword | null) {
        // @ts-ignore
        AuthModule.setAuthorizationHeaderAndUser(updatedUser);
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        if (this.user === null) {
            this.goToPreviousPage();
            return;
        }

        if (typeof this.user.image.fileObject === "undefined") {
            const userImageObject = await getFileObjectByUrl(
                this.user.image.src
            );
            this.user = {
                ...this.user,
                image: {
                    ...this.user.image,
                    fileObject: userImageObject,
                },
            };
        }

        this.isCRUDDialogVisible = true;
    }

    /**
     * Navigate to previous page
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private goToPreviousPage() {
        this.$router.go(-1);
    }
}
